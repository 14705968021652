import React from "react"
import { graphql, Link } from "gatsby"
import { Box, Grid, Themed } from "theme-ui"

const IndexPage = ({ data }) => {

  const dataFragments = data.indexJason
  const dataFragmentsHeader = data.headerJson

  return (
    <Themed.root>
      <Grid
        as={`div`}
        gap={0}
        sx={{
          gridTemplateColumns: `1fr`,
          gridTemplateRows: [ `100vh auto auto`,
                              `100vh auto auto`,
                              `100vh auto auto`,
                              `100vh 1fr auto`,
                              `100vh 1fr auto`,
                              `100vh 1fr auto`
                            ],
          gridTemplateAreas:[`"hero" "main" "footer"`],
          header:{
            position: `absolute`,
            top: 3,
            right: 3,
          },
          ".hero": {
            backgroundColor: `gray.2`,
            gridArea: `hero`,
            px: 3,
            paddingBottom: 3,
            display: `grid`,
            placeItems: `center flex-start`,
          },
          ".hero-content p": {
            color: `black`,
            maxWidth: [`auto`, `auto`, `auto`, `75%`, `50%`, `50%`],
          },
          ".link-to-content": {
            color: `black`,
            borderBottom: `none`,
            display: `inline-block`,
            ":hover": {
              color: `black`,
              borderBottom: `none`,
            }
          },
          main: {
            gridArea: `main`,
            px: 3,
          },
          footer: {
            gridArea: `footer`,
          },
          h1: {
            color: `black`,
            fontSize: [7, 8, 8, 9, 10, 11],
            fontWeight: `normal`,
          },
          ".link-to-lang": {
            color: `black`,
            borderBottom: `none`,
            fontSize: 0,
            textTransform: `uppercase`,
            ":hover": {
              color: `black`,
              borderBottom: theme => `1px solid ${theme.colors.gray[1]}`,
            }
          },
          ".content": {
            my: 5,
            maxWidth: [`auto`, `auto`, `auto`, `75%`, `50%`, `50%`],
          },
          ".items": {
            gridTemplateColumns: [  `1fr 1fr`,
                                    `1fr 1fr 1fr`,
                                    `1fr 1fr 1fr`,
                                    `1fr 1fr 1fr`,
                                    `1fr 1fr 1fr`,
                                    `1fr 1fr 1fr`
            ]
          },
          ".items-stack": {
            gridTemplateColumns: [  `1fr`,
                                    `1fr 1fr 1fr`,
                                    `1fr 1fr 1fr`,
                                    `1fr 1fr 1fr`,
                                    `1fr 1fr 1fr`,
                                    `1fr 1fr 1fr`
            ]
          },
          ".items h2, .items-stack h4": {
            fontSize: 1,
            my: 2,
          },
          ".subtitle": {
            fontSize: 1,
            fontWeight: `body`,
            margin: 0,
            "&:after": {
              backgroundColor: `black`,
              content: `""`,
              display: `block`,
              height: `1px`,
              width: 6,
              my: 2,
            },
          }
        }}
      >
        <Box as="header">
          <Box as="div" className="nav">
            <Themed.a href={dataFragmentsHeader.fragment_3} className="nav-link link-to-lang">{dataFragmentsHeader.fragment_2}</Themed.a> | <Themed.a href={dataFragmentsHeader.fragment_5} className="nav-link link-to-lang">{dataFragmentsHeader.fragment_4}</Themed.a> | <Themed.a href={dataFragmentsHeader.fragment_7} className="nav-link link-to-lang">{dataFragmentsHeader.fragment_6}</Themed.a>
          </Box>
        </Box>
        <Box className="hero">
          <Box className="hero-content">
            <Themed.h1 dangerouslySetInnerHTML={{ __html:dataFragments.fragment_1}}/>
            <Themed.p dangerouslySetInnerHTML={{ __html:dataFragments.fragment_2}} />
            <Themed.a as={Link} to="#main" arial-label="link-to-main-content" className="link-to-content">&#x02193;</Themed.a>
          </Box>
        </Box>
        <Box
          as={`main`}
          id="main"
          className="main-content"
          sx={{
            variant: 'styles',
          }}
        >
          <Box className="content">
            <Box
              dangerouslySetInnerHTML={{ __html:dataFragments.fragment_3}}
            />
            <Box
              dangerouslySetInnerHTML={{ __html:dataFragments.fragment_4}}
            />
            <Box
              dangerouslySetInnerHTML={{ __html:dataFragments.fragment_5}}
            />
          </Box>
        </Box>
      </Grid>
    </Themed.root>
  )

}

export default IndexPage

export const pageQuery = graphql`
  query IndexPage {
    indexJason: dataJson(slug: {eq: "page-index"}) {
      fragment_1
      fragment_2
      fragment_3
      fragment_4
      fragment_5
    }
    headerJson: dataJson(slug: {eq: "header"}) {
      fragment_2
      fragment_3
      fragment_4
      fragment_5
      fragment_6
      fragment_7
    }
  }
`
